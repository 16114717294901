/* Ensure the container takes full width and height */
.creative_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #f5f5f5;
    overflow-x: hidden;
    color: #333;
    position: relative;
    /* Ensure proper stacking context */
}

/* Middle section for scrolling */
.middle-section {
    flex: 1;
    display: flex;
    overflow-x: scroll;
    width: 100%;
    padding: 10px;
    background-color: #f5f5f5;
    z-index: 4;
    justify-content: space-evenly;
    position: sticky;
    top: 0;
}

.story_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.story_container>img {
    width: 80px;
    /* Set a fixed width */
    height: 80px;
    /* Set a fixed height */
    border: 3px solid brown;
    border-radius: 50%;
    /* Make the image round */
    object-fit: cover;
    /* Ensure the image covers the container without distortion */
    transition: border-color 0.3s ease;
    /* Smooth transition for border color */
}

.story_container.active {
    border-color: blue;
    /* Change border color to blue when active */
}

/* Post section responsiveness */
.post-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0.5rem 1rem;
    width: 100%;
    max-width: 800px;
    position: relative;
    /* Ensure no overlap */
    z-index: 2;
    height: 100vh;
    /* Ensure it's above other elements */
}

/* Post container */
.post {
    background: #fff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 540px;
    /* Matches YouTube's max width */
    position: relative;
    /* Ensure no overlap */
    z-index: 3;
    /* Ensure it's above other elements */
}

.insta-post {
    background: #fff;
    border-radius: 8px;
    padding: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    min-width: 420px;
    /* Matches YouTube's max width */
    position: relative;
    /* Ensure no overlap */
    z-index: 3;
    border: 1px olid red;
    /* Ensure it's above other elements */
}



/* Video iframe styling */
.post-video>iframe {
    width: 100%;
    height: 400px;
    border: none;
    z-index: 4;
    /* Ensure iframe is clickable */
    position: relative;
    pointer-events: auto;
    /* Ensure touch events are enabled */
}

/* Post header styling */
.post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    z-index: 2;
    /* Ensure it doesn't block the iframe */
    position: relative;
}

/* Post user styling */
.post-user {
    display: flex;
    align-items: center;
    color: #333;
}

.post-user img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
}

/* Post title container */
.post_title_container {
    display: flex;
    flex-direction: column;
    text-align: left;
}

/* Post footer styling */
.post-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    z-index: 2;
    /* Ensure it doesn't block the iframe */
    position: relative;
}

/* Post actions styling */
.post-actions {
    display: flex;
    gap: 15px;
}

.post-actions svg {
    font-size: 20px;
    cursor: pointer;
    color: #333;
    transition: color 0.3s ease;
}

.post-actions svg:hover {
    color: #555;
}

/* Post caption styling */
.post-caption {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    text-align: left;
}

.post-caption>span {
    color: blue;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .post {
        padding: 10px;
    }

    .post-caption {
        font-size: 12px;
    }

    .post-video iframe {
        height: 400px;
        /* Adjust height for smaller screens */
    }
}

@media (max-width: 480px) {
    .post-user img {
        width: 30px;
        height: 30px;
    }

    .post-caption {
        font-size: 10px;
    }

    .post-video iframe {
        width: 100%;
        height: 280px;
        /* Adjust height for smaller screens */
    }
}